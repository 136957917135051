<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="add()"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="标题"
          v-model="searchParam.neighborhoodName"
          placeholder="请输入标题"
        />
        <v-select
          clearable
          :options="releaseStatus"
          v-model="searchParam.publishStatus"
          label="发布状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="edit(scope.row)"
        />
      </template>
    </v-list>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      width="50%"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="用户类别" prop="authSpaceTypes">
          <div class="check-panel" style="display: flex">
            <v-checkbox
              v-model="item.checked"
              v-for="(item, index) in userTypeOps"
              :key="index"
              :index="index"
              type="border"
              :label="item.label"
              @change="checkChange"
            />
          </div>
        </el-form-item>
        <el-form-item
          v-show="
            form.authSpaceTypes !== undefined && form.authSpaceTypes !== ''
          "
          label="区域"
          prop="authSpaceIds"
        >
          <v-cascader
            v-model="authSpaceIds"
            :options="sectionOps"
            :props="props"
          />
        </el-form-item>
        <el-form-item label="标题">
          <v-input
            clearable
            class="filter-item"
            v-model="form.neighborhoodName"
          ></v-input>
        </el-form-item>
        <el-form-item label="内容" prop="neighborhoodText">
          <div id="editor">
            <v-ueditor v-model="form.neighborhoodText" />
          </div>
        </el-form-item>
        <el-form-item label="是否点赞">
          <v-radio :radioObj="isLikeObj" v-model="form.isLike"></v-radio>
        </el-form-item>
        <el-form-item label="是否评价">
          <v-radio
            :radioObj="isEvaluateObj"
            v-model="form.isEvaluate"
          ></v-radio>
        </el-form-item>
        <el-form-item label="发布状态">
          <v-select :options="releaseStatusOps" v-model="form.publishStatus" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button
          size="medium"
          type="primary"
          @click="submit()"
          text="保存"
        ></v-button>
        <v-button
          size="medium"
          @click="dialogFormVisible = false"
          text="取消"
        ></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addNeighborhoodConventionUrl,
  neighborhoodConventionListUrl,
  communityListUrl,
  updateNeighborhoodConventionUrl,
  getBuildListURL,
} from "./api.js";
import {
  releaseStatusMap,
  releaseStatus,
  releaseStatusOps,
  isLikeObj,
  isLikeMap,
  isLikeOps,
  isEvaluateObj,
  isEvaluateMap,
  isEvaluateOps,
  setUserTypeOps,
} from "./map.js";
import { Message, MessageBox } from "element-ui";
export default {
  name: "conventionEditor",
  data() {
    return {
      userTypeOps: setUserTypeOps(),
      releaseStatusOps: releaseStatusOps(),
      releaseStatus,
      dialogTitle: null,
      dialogFormVisible: false,
      searchParam: {
        publishStatus: null,
        neighborhoodName: null,
        spaceId: null,
      },
      tableUrl: neighborhoodConventionListUrl,
      headers: [
        {
          prop: "spaceName",
          label: "小区名称",
        },
        {
          prop: "neighborhoodName",
          label: "标题",
        },
        // {
        //   prop: "neighborhoodText",
        //   showOverflowTooltip: true,
        //   label: "内容",
        // },
        {
          prop: "isLike",
          label: "是否点赞",
          formatter: (row, prop) => {
            return isLikeMap[row.isLike];
          },
        },
        {
          prop: "isEvaluate",
          label: "是否评论",
          formatter: (row, prop) => {
            return isEvaluateMap[row.isEvaluate];
          },
        },
        {
          prop: "publishStatus",
          label: "发布状态",
          formatter: (row, prop) => {
            return releaseStatusMap[row.publishStatus];
          },
        },
      ],
      isLikeObj: [
        {
          name: 0,
          value: "否",
        },
        {
          name: 1,
          value: "是",
        },
      ],
      isEvaluateObj: [
        {
          name: 0,
          value: "否",
        },
        {
          name: 1,
          value: "是",
        },
      ],
      communityList: [],
      form: {
        id: null,
        neighborhoodName: null,
        neighborhoodText: null,
        isLike: null,
        isEvaluate: null,
        publishStatus: null,
        authSpaceIds: "", // 区域id集合
        authSpaceTypes: undefined, // 用户类别id
      },
      props: {
        multiple: false,
        label: "name",
        value: "id",
        children: "children",
      },
      residentsOps: [], // 用户
      merchantsOps: [], // 商圈
      allOps: [], // 用户+商圈
      spacePathIds: [], // 添加treeIds的数据
      sectionOps: [], // 用于显示的数据
      authSpaceIds: [],
    };
  },
  async mounted() {
    // 10 居民 11 商户
    await Promise.all([
      this.getBuildList(10),
      this.getBuildList(11),
      this.getBuildList("10,11"),
    ]);
  },
  computed: {
    authSpaceTypes() {
      return this.form.authSpaceTypes;
    },
  },
  watch: {
    // 选中的用户类别集合
    userTypeOps: {
      handler(val) {
        const checkOps = val.filter((v) => v.checked);
        this.form.authSpaceTypes =
          checkOps.length > 0
            ? checkOps.map((v) => v.value).join(",")
            : undefined;
      },
      deep: true,
    },
    authSpaceTypes() {
      let types =
        this.form.authSpaceTypes !== undefined
          ? this.form.authSpaceTypes.split(",")
          : [];
      let result = [];
      if (types.includes("11") && types.includes("10")) {
        result = [...this.allOps];
      } else if (types.includes("11")) {
        result.push(...this.merchantsOps);
      } else if (types.includes("10")) {
        result.push(...this.residentsOps);
      }
      this.sectionOps = result;
    },
    // 选中的区域集合
    authSpaceIds: {
      handler(val) {
        this.form.authSpaceIds = val[val.length - 1];
        console.log(val);
        console.log(this.form.authSpaceIds);
      },
      deep: true,
    },
  },
  methods: {
    // 数据添加 parentIds
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.spacePathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      console.log(data);
      return data;
    },
    edit(data) {
      console.log(data);
      this.dialogFormVisible = true;
      this.dialogTitle = "编辑";
      this.form.id = data.id;
      this.form.neighborhoodName = data.neighborhoodName;
      this.form.isLike = data.isLike;
      this.form.isEvaluate = data.isEvaluate;
      this.form.publishStatus = data.publishStatus;
      this.authSpaceIds = [];
      this.form.authSpaceIds = data.authSpaceIds;
      this.form.authSpaceTypes = data.authSpaceTypes;
      let types = data.authSpaceTypes.split(",");
      this.userTypeOps.forEach((v) => {
        if (types.includes(v.value)) {
          v.checked = true;
        } else {
          v.checked = false;
        }
      });
      const ids = this.form.authSpaceIds;
      this.spacePathIds.forEach((item) => {
        if (ids == item.id) {
          this.authSpaceIds = item.treeIds;
        }
      });
      this.$nextTick(() => {
        this.form.neighborhoodText = data.neighborhoodText;
      });
    },
    add() {
      this.dialogFormVisible = true;
      this.dialogTitle = "新增";
      this.form.neighborhoodName = null;
      this.form.neighborhoodText = null;
      this.form.isLike = null;
      this.form.isEvaluate = null;
      this.form.publishStatus = null;
      this.form.authSpaceIds = "";
      this.form.authSpaceTypes = undefined;
      this.userTypeOps.forEach((item) => {
        item.checked = false;
      });
    },
    async getBuildList(type) {
      let res = await this.$axios.get(
        `${getBuildListURL}?specifyCollectionTypes=${type}`
      );
      if (res.code === 200) {
        let opt = this.dealDataAddParentIds(res.data);
        console.log(res.data, opt, "opt---");
        // 10 用户 11 商圈
        if (type === 10) {
          this.residentsOps = opt;
        } else if (type === 11) {
          this.merchantsOps = opt;
        } else {
          this.allOps = opt;
        }
      }
    },
    submit() {
      if (this.form.authSpaceTypes == "") {
        this.$message.error("请选择用户类别！");
        return;
      }
      if (this.form.authSpaceIds.length == 0) {
        this.$message.error("请选择区域！");
        return;
      }
      if (!this.form.neighborhoodName || this.form.neighborhoodName == "") {
        this.$message.error("请输入标题！");
        return;
      }
      if (!this.form.neighborhoodText || this.form.neighborhoodText == "") {
        this.$message.error("请输入内容！");
        return;
      }
      if (this.form.isLike == null) {
        this.$message.error("请选择是否点赞！");
        return;
      }
      if (this.form.isEvaluate == null) {
        this.$message.error("请选择是否评论！");
        return;
      }
      if (this.form.publishStatus == null) {
        this.$message.error("请选择发布状态！");
        return;
      }

      if (this.dialogTitle == "新增") {
        let params = {
          neighborhoodName: this.form.neighborhoodName,
          neighborhoodText: this.form.neighborhoodText,
          publishStatus: this.form.publishStatus,
          isLike: this.form.isLike,
          isEvaluate: this.form.isEvaluate,
          authSpaceTypes: this.form.authSpaceTypes,
          authSpaceIds: this.form.authSpaceIds,
        };
        this.$axios
          .post(`${addNeighborhoodConventionUrl}`, params)
          .then((res) => {
            if (res.code === 200) {
              this.dialogFormVisible = false;
              Message.success(res.msg);
              this.$refs.list.search();
            }
          });
      } else if (this.dialogTitle == "编辑") {
        let params = {
          id: this.form.id,
          neighborhoodName: this.form.neighborhoodName,
          neighborhoodText: this.form.neighborhoodText,
          publishStatus: this.form.publishStatus,
          isLike: this.form.isLike,
          isEvaluate: this.form.isEvaluate,
          authSpaceTypes: this.form.authSpaceTypes,
          authSpaceIds: this.form.authSpaceIds,
        };
        this.$axios
          .post(`${updateNeighborhoodConventionUrl}`, params)
          .then((res) => {
            if (res.code === 200) {
              this.dialogFormVisible = false;
              Message.success(res.msg);
              this.$refs.list.search();
            }
          });
      }
    },
    checkChange() {
      // 每次改变时, 清空默认选择项
      this.authSpaceIds = [];
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
  .check-panel {
    display: flex;
  }
}
</style>
